import { docTypes } from "../common/constants.lib";
import * as _ from 'lodash';
import { CasiDateRange } from "../common/obj.lib";
import moment from 'moment';

import { APP_CONFIG } from '../setup/config';
import {escapeStyle} from "../common/utils.lib";

export interface IIdish {
  id: number
};

export interface IRole {
  id?: number,
  name?: string,
  permission_cnt?: string
};

export class Role implements IRole {
  static create(args: any) {
    const {
      id, name, permission_cnt
    } = args;
    return new Role(id, name, permission_cnt);
  }
  constructor(
    public id?: number,
    public name?: string,
    public permission_cnt?: string
  ) {
  }

  get permissionCnt(): number {
    if (this.permission_cnt)
      return +this.permission_cnt;
    return 0;
  }
};

export interface IPermission {
  id: number,
  entity: string,
  action: string,
  display_action: string,
  selected: boolean
};

export class Permission implements IPermission {
  static create(args: any) {
    const {
      id, entity, action,
      display_action
    } = args;
    return new Permission(id, entity, action, display_action);
  }

  constructor(
    public id: number,
    public entity: string,
    public action: string,
    public display_action: string,
    public selected: boolean = false
  ) { }

  set select(val: boolean) {
    this.selected = val;
  }
  get select() {
    return this.selected;
  }

  getRolePermission(roleId: number): RolePermission {
    return new RolePermission(roleId, this.id);
  }
};

export interface IRolePermission {
  role_id: number,
  permission_id: number
};

export class RolePermission implements IRolePermission {
  static createFrom(role: Role, permission: Permission) {
    return new RolePermission((role.id as number), permission.id);
  }

  static create(args: any) {
    const { role_id, permission_id } = args;
    return new RolePermission(
      role_id,
      permission_id
    );
  }

  constructor(
    public role_id: number,
    public permission_id: number
  ) { }
}

export class RolePermissionSelectable {
  constructor() { }
}

export interface IUser {
  id?: number,
  first_name?: string,
  last_name?: string,
  email?: string,
  research_consent?: boolean,
  accepted_terms_version?: boolean,
  active?: boolean,
  userRoles?: any[]
};

export class User implements IUser {
  static create(args: any) {
    const {
      id, first_name, last_name,
      email, research_consent,
      accepted_terms_version, active
    } = args;
    return new User(
      id, email, active,
      first_name, last_name,
      research_consent, accepted_terms_version
    );
  }

  public userRoles: any[] = [];

  constructor(
    public id?: number,
    public email: string = '',
    public active: boolean = false,
    public first_name?: string,
    public last_name?: string,
    public research_consent?: boolean,
    public accepted_terms_version?: boolean
  ) {
    this.userRoles = [];
  }

  addRoles(roles: any[]) {
    this.userRoles = [...roles];
  }
};

export interface IUserRole {
  role_id: number
};

export class UserRole implements IUserRole {
  static create(args: any) {
    const { role_id } = args;
    return new UserRole(role_id);
  }
  constructor(
    public role_id: number
  ) { }
};

export interface IEnquiry {
  id: number,
  first_name?: string,
  last_name?: string,
  email: string,
  phone?: string,
  subject?: string,
  message?: string,
  completed: boolean,
  user_id: number,
  createdAt: string
};

export class Enquiry implements IEnquiry {
  static create(args: any) {
    const {
      id, first_name, last_name,
      email, phone,
      subject, message, completed,
      user_id, createdAt
    } = args;
    return new Enquiry(
      id, email, completed, user_id,
      createdAt,
      first_name, last_name,
      phone, subject,
      message
    );
  }

  constructor(
    public id: number,
    public email: string,
    public completed: boolean,
    public user_id: number,
    public createdAt: string,
    public first_name?: string,
    public last_name?: string,
    public phone?: string,
    public subject?: string,
    public message?: string
  ) {
  }
};

export interface IEnquiryAddress {
  id?: number,
  address?: string,
};

export class EnquiryAddress implements IEnquiryAddress {
  static create(args: any) {
    const {
      id, address
    } = args;
    return new EnquiryAddress(
      id, address
    );
  }

  constructor(
    public id?: number,
    public address?: string
  ) { }
};

export interface IHelpInfo {
  id?: number;
  site_section?: string;
  element_name?: string;
  html?: string;
  heading?: string;
};

export class HelpInfo implements IHelpInfo {
  static create(args: any) {
    const {
      id, site_section, element_name,
      html, heading
    } = args;
    return new HelpInfo(
      id, site_section, element_name, html,
      heading
    );
  }

  constructor(
    public id?: number,
    public site_section?: string,
    public element_name?: string,
    public html?: string,
    public heading?: string
  ) {
  }
};

export interface ITerms {
  id?: number;
  html?: string;
  version_number?: number;
  approved?: boolean;
  doc_type?: string;
};

export class Terms implements ITerms {

  static create(args: any) {
    const {
      id, html, version_number,
      approved, doc_type
    } = args;
    return new Terms(id, html, version_number,
      approved || false, doc_type);
  }

  constructor(
    public id?: number,
    public html?: string,
    public version_number?: number,
    public approved: boolean = false,
    public doc_type: string = docTypes[0].value
  ) {
    console.log('Terms:constructor: ', this);
  }

  prepareForSubmit(): any {
    return {
      html: this.html,
      version_number: this.version_number ? this.version_number++ : 1,
      doc_type: this.doc_type
    }
  }
};

export interface ICoBenefitType {
  id?: number;
  co_benefit?: string;
  co_benefit_group?: string;
  sub_co_benefit?: string;
  active?: boolean;
};

export class CoBenefitType implements ICoBenefitType {
  static create(args: any) {
    const {
      id, co_benefit, co_benefit_group,
      sub_co_benefit, active
    } = args;
    return new CoBenefitType(id, co_benefit, co_benefit_group,
      sub_co_benefit, active);
  }

  public active?: boolean;

  constructor(
    public id?: number,
    public co_benefit?: string,
    public co_benefit_group?: string,
    public sub_co_benefit?: string,
    active?: boolean
  ) {
    this.active = active || (!active && active !== false) ? true : false;
  }

  toggleActive() {
    this.active = !this.active;
  }
};

export interface IElegibility {
  id?: number;
  description?: string;
};

export class Elegibility implements IElegibility {
  static create(args: any) {
    const {
      id, description
    } = args;
    return new Elegibility(
      id,
      description
    );
  }

  constructor(
    public id?: number,
    public description?: string
  ) {
  }
};

export interface MayHaveId {
  id?: number;
  checked?: boolean;
  description?: string;
}

export interface ICommonDescription {
  description: string;
}

export interface ICommonFetch extends MayHaveId {
  deleted: boolean;

  delete(): void;
};

export class CommonFetch implements ICommonFetch, ICommonDescription {
  static create(args: any) {
    const { id, description, deleted } = args;
    return new CommonFetch(
      id, description, deleted
    );
  }

  public checked: boolean = false;

  constructor(public id: number,
    public description: string,
    public deleted: boolean,
  ) { }

  delete() {
    this.deleted = true;
  };
};

export interface ICommonGoal {
  goal: string;
  checked: boolean;
}

export class CommonGoal implements ICommonFetch, ICommonGoal {
  static create(args: any) {
    const { id, goal, deleted } = args;
    return new CommonGoal(
      id, goal, deleted
    );
  }

  public checked: boolean = false;

  constructor(public id: number,
    public goal: string,
    public deleted: boolean,
  ) { }

  delete() {
    this.deleted = true;
  }
};

export class CommonClassification extends CommonFetch {
};

export class CommonIndustry extends CommonFetch {
};

export class CommonBenefitType extends CommonFetch {
};

export class CommonState extends CommonFetch {
};

export class CommonElegibility extends CommonFetch {
};

export interface IOpportunity {
  id?: number;
  title?: string;
  short_description?: string;
  full_description?: string;
  opportunity_goals?: any[];
  classification_id?: number;
  classification_description?: string;
  industries?: any[];
  states?: any[];
  organisation_name?: string;
  organisation_url?: string;
  service_providers?: string;
  benefit_types?: any[];
  benefits?: string;
  negatives?: string;
  risks?: string;
  costs?: string;
  attribution?: string;
  funding_scheme_start?: string;
  funding_scheme_end?: string;
  potential_financial_benefits?: string;
  updated_by_user_id?: number;
  updated_by_user?: string;
  updated_on?: string;
  deleted?: boolean;
  co_benefits?: string;
  demonstrated_financial_benefit?: number;
  certification_accreditation_scheme?: number;
  anz_iso_certification?: number;
  demonstrated_access_to_markets?: number;
  public_information?: number;
  information_quality?: number;
  total_ranking?: number;
  practices?: string;
  reference_links?: string;
  organisation_contact_email?: string;
  elegibility_json?: any[];
  elegibility_text?: string;
  demonstrated_risks?: number;
  demonstrated_costs?: number;
  demonstrated_non_financial_benefits?: number;
  opportunity_reviews?:number;

  _goalList: CommonGoal[];
  _industryList: CommonIndustry[];
  _stateList: CommonState[];
  _benefitTypeList: CommonBenefitType[];
  _classificationList: CommonClassification[];
  _elegibilityList: CommonElegibility[];

  delete(): void;
  setLists(): void;

  totalRanking(): void;

  setFundingDates(range: CasiDateRange): void;

  // getGoals(list: CommonGoal[]): CommonGoal[];
  // setGoals(list: CommonGoal[]): void;

  // getIndustries(list: CommonIndustry[]): CommonIndustry[];
  // setIndustries(list: CommonIndustry[]): void;

  // getStates(list: CommonState[]): CommonState[];
  // setStates(list: CommonState[]): void;

  // getList<T extends MayHaveId, K extends keyof this>(list: T[], propName: K): T[];
  // setList<T extends MayHaveId, K extends keyof this>(list: T[], propName: K): void;
};

export class Opportunity implements IOpportunity {
  static create(args: any) {
    const {
      id,
      title,
      short_description,
      full_description,
      opportunity_goals,
      classification_id,
      classification_description,
      industries,
      states,
      organisation_name,
      organisation_url,
      service_providers,
      benefit_types,
      benefits,
      negatives,
      risks,
      costs,
      attribution,
      funding_scheme_start,
      funding_scheme_end,
      potential_financial_benefits,
      updated_by_user_id,
      updated_by_user,
      updated_on,
      deleted,
      co_benefits,
      demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      total_ranking,
      practices,
      reference_links,
      organisation_contact_email,
      elegibility_json, elegibility_text,
      demonstrated_risks, demonstrated_costs,
      demonstrated_non_financial_benefits,
      resources, opportunity_reviews
    } = args;
    return new Opportunity(
      [], [], [], [], [], [],
      id,
      title,
      short_description,
      full_description,
      opportunity_goals,
      classification_id,
      classification_description,
      industries,
      states,
      organisation_name,
      organisation_url,
      service_providers,
      benefit_types,
      benefits,
      negatives,
      risks,
      costs,
      attribution,
      funding_scheme_start,
      funding_scheme_end,
      potential_financial_benefits,
      updated_by_user_id,
      updated_by_user,
      moment(updated_on).format("DD/MM/YYYY"),
      deleted,
      co_benefits,
      demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      total_ranking,
      practices,
      reference_links,
      organisation_contact_email,
      elegibility_json, elegibility_text,
      demonstrated_risks, demonstrated_costs,
      demonstrated_non_financial_benefits,
      resources, opportunity_reviews
    );
  }

  static createWithLists(args: any, goalList: CommonGoal[],
    industryList: CommonIndustry[], stateList: CommonState[],
    benefitTypeList: CommonBenefitType[],
    classificationList: CommonClassification[],
    elegibilityList: CommonElegibility[]) {
    const {
      id,
      title,
      short_description,
      full_description,
      opportunity_goals,
      classification_id,
      classification_description,
      industries,
      states,
      organisation_name,
      organisation_url,
      service_providers,
      benefit_types,
      benefits,
      negatives,
      risks,
      costs,
      attribution,
      funding_scheme_start,
      funding_scheme_end,
      potential_financial_benefits,
      updated_by_user_id,
      updated_by_user,
      updated_on,
      deleted,
      co_benefits,
      demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      total_ranking,
      practices,
      reference_links,
      organisation_contact_email,
      elegibility_json, elegibility_text,
      demonstrated_risks, demonstrated_costs,
      demonstrated_non_financial_benefits,
      resources, opportunity_reviews
    } = args;
    return new Opportunity(
      goalList, industryList, stateList,
      benefitTypeList,
      classificationList,
      elegibilityList,
      id,
      title,
      short_description,
      full_description,
      opportunity_goals,
      classification_id,
      classification_description,
      industries,
      states,
      organisation_name,
      organisation_url,
      service_providers,
      benefit_types,
      benefits,
      negatives,
      risks,
      costs,
      attribution,
      funding_scheme_start,
      funding_scheme_end,
      potential_financial_benefits,
      updated_by_user_id,
      updated_by_user,
      updated_on,
      deleted,
      co_benefits,
      demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      total_ranking,
      practices,
      reference_links,
      organisation_contact_email,
      elegibility_json, elegibility_text,
      demonstrated_risks, demonstrated_costs,
      demonstrated_non_financial_benefits,
      resources,
      opportunity_reviews
    );
  }

  // public isStateSelected: boolean = false;
  // public stateLabel: string = "";

  _goalList: CommonGoal[] = [];
  _industryList: CommonIndustry[] = [];
  _stateList: CommonState[] = [];
  _benefitTypeList: CommonBenefitType[] = [];
  _classificationList: CommonClassification[] = [];
  _elegibilityList: CommonElegibility[] = [];

  goalListDisplay: CommonGoal[] = [];
  industryListDisplay: CommonIndustry[] = [];
  stateListDisplay: CommonState[] = [];
  benefitListDisplay: CommonBenefitType[] = [];
  classificationDisplay: CommonClassification[] = [];
  elegibilityDisplay: CommonElegibility[] = [];

  constructor(
    goalList?: CommonGoal[],
    industryList?: CommonIndustry[],
    stateList?: CommonState[],
    benefitTypeList?: CommonBenefitType[],
    classificationList?: CommonClassification[],
    elegibilityList?: CommonElegibility[],
    public id?: number,
    public title?: string,
    public short_description?: string,
    public full_description?: string,
    public opportunity_goals?: any[],
    public classification_id?: number,
    public classification_description?: string,
    public industries?: any[],
    public states?: any[],
    public organisation_name?: string,
    public organisation_url?: string,
    public service_providers?: string,
    public benefit_types?: any[],
    public benefits?: string,
    public negatives?: string,
    public risks?: string,
    public costs?: string,
    public attribution?: string,
    public funding_scheme_start?: string,
    public funding_scheme_end?: string,
    public potential_financial_benefits?: string,
    public updated_by_user_id?: number,
    public updated_by_user?: string,
    public updated_on?: string,
    public deleted?: boolean,
    public co_benefits?: string,
    public demonstrated_financial_benefit?: number,
    public certification_accreditation_scheme?: number,
    public anz_iso_certification?: number,
    public demonstrated_access_to_markets?: number,
    public public_information?: number,
    public information_quality?: number,
    public total_ranking?: number,
    public practices?: string,
    public reference_links?: string,
    public organisation_contact_email?: string,
    public elegibility_json?: any[],
    public elegibility_text?: string,
    public demonstrated_risks?: number,
    public demonstrated_costs?: number,
    public demonstrated_non_financial_benefits?: number,
    public resources?: number,
    public opportunity_reviews?: number
  ) {
    if (goalList) {
      this._goalList = goalList;
    }
    if (industryList) {
      this._industryList = industryList;
    }
    if (stateList) {
      this._stateList = stateList;
    }
    if (benefitTypeList) {
      this._benefitTypeList = benefitTypeList;
    }
    if (elegibilityList) {
      this._elegibilityList = elegibilityList;
    }
    if (classificationList) {
      this.classificationDisplay = classificationList;
    }
    this.goalListDisplay = this.goalList;
    this.industryListDisplay = this.industryList;
    this.stateListDisplay = this.stateList;
    this.benefitListDisplay = this.benefitTypeList;
    this.elegibilityDisplay = this.elegibilityList;

    // this.checkStateSelection();
  }

  delete() {
    this.deleted = true;
  }

  setFundingDates(range: CasiDateRange): void {
    const { start, end } = range;
    this.funding_scheme_start = start;
    this.funding_scheme_end = end;
  }

  totalRanking(): void {
    this.total_ranking = (this.demonstrated_financial_benefit || 0) +
      (this.certification_accreditation_scheme || 0) +
      (this.anz_iso_certification || 0) +
      (this.demonstrated_access_to_markets || 0) +
      (this.public_information || 0) +
      (this.information_quality || 0) +
      (this.demonstrated_non_financial_benefits || 0) +
      (this.demonstrated_risks || 0) +
      (this.demonstrated_costs || 0);
  }

  get totalledRanking(): number {
    return this.total_ranking || 0;
  }

  get goalList(): CommonGoal[] {
    let returning = [...this._goalList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.opportunity_goals, "id"), r.id) > -1;
    }
    return returning;
  }
  set goalList(list: CommonGoal[]) {
    this.opportunity_goals = _.map(_.filter(list, 'checked'),
      d => {
        let { id, goal } = d;
        return { id, goal };
      })
  }

  get industryList(): CommonIndustry[] {
    let returning = [...this._industryList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.industries, "id"), r.id) > -1;
    }
    return returning;
  }
  set industryList(list: CommonIndustry[]) {
    this.industries = _.map(_.filter(list, 'checked'),
      d => {
        let { id, description } = d;
        return { id, description };
      })
  }

  get stateList(): CommonState[] {
    let returning = [...this._stateList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.states, "id"), r.id) > -1;
    }
    return returning;
  }
  set stateList(list: CommonState[]) {
    this.states = _.map(_.filter(list, 'checked'),
      d => {
        let { id, description } = d;
        return { id, description };
      })
  }

  get elegibilityList() {
    let returning = [...this._elegibilityList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.elegibility_json, "id"), r.id) > -1;
    }
    return returning;
  }

  set elegibilityList(list) {
    this.elegibility_json = _.map(_.filter(list, "checked"),
      d => {
        let { id, description } = d;
        return { id, description };
      });
  }

  get benefitTypeList(): CommonBenefitType[] {
    let returning = [...this._benefitTypeList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.benefit_types, "id"), r.id) > -1;
    }
    return returning;
  }
  set benefitTypeList(list: CommonBenefitType[]) {
    this.benefit_types = _.map(_.filter(list, 'checked'),
      d => {
        let { id, description } = d;
        return { id, description };
      })
  }

  get isStateSelected(): boolean {
    return this.stateListDisplay.filter(s => s.checked).length > 0;
  }

  get stateLabel(): string {
    return this.isStateSelected ? "Clear" : "Select";
  }

  toggleAllStates() {
    if (this.stateListDisplay) {
      let toggleFlag = this.isStateSelected ? false : true;
      this.stateListDisplay.forEach(s => s.checked = toggleFlag);
    }
    // if (this.states) {
    //   this.states.map(s => s.checked = this.isStateSelected ? false : true);
    //   // this.checkStateSelection();
    // }
  }

  selectState(item: any) {
    item.checked = !item.checked;
    // this.checkStateSelection();
  }

  // checkStateSelection() {
  //   // this.isStateSelected = this.states ? this.states.filter(s => s.checked).length > 0 : false;
  //   this.stateLabel = this.isStateSelected ? "Clear" : "Select";
  // }

  setLists(): void {
    this.goalList = this.goalListDisplay;
    this.industryList = this.industryListDisplay;
    this.stateList = this.stateListDisplay;
    this.benefitTypeList = this.benefitListDisplay;
    this.elegibilityList = this.elegibilityDisplay;
  }
};

export interface ICoBenefit {
  id?: number,
  opportunity_id?: number;
  co_benefit_type_id?: number;
  positive?: boolean;
  negative?: boolean;
  neutral?: boolean;
  summary?: string;
  visible?: boolean;
  co_benefit?: string;
  co_benefit_group?: string;
  sub_co_benefit?: string;

  optionSelected?: boolean;
  coBenefitTree?: any;

  radioOptions: any[];

  setOptionSelected(): void;
  setBooleanValues(): void;
};

export class CoBenefit implements ICoBenefit {
  static create(args: any) {
    const {
      id, opportunity_id, co_benefit_type_id,
      positive, negative, neutral,
      summary, visible, co_benefit,
      co_benefit_group, sub_co_benefit,
      optionSelected,
      coBenefitTree
    } = args;
    return new CoBenefit(
      id, opportunity_id,
      coBenefitTree ? coBenefitTree.id : co_benefit_type_id,
      positive, negative, neutral,
      summary, visible, co_benefit,
      co_benefit_group, sub_co_benefit,
      optionSelected
    );
  }
  static createWithOppId(opportunityId: number) {

    return new CoBenefit(
      undefined, opportunityId
    );
  }

  public radioOptions: any[] = [
    { value: "positive", text: "Positive" },
    { value: "negative", text: "Negative" },
    { value: "neutral", text: "Neutral" }
  ];

  constructor(
    public id?: number,
    public opportunity_id?: number,
    public co_benefit_type_id?: number,
    public positive?: boolean,
    public negative?: boolean,
    public neutral?: boolean,
    public summary?: string,
    public visible: boolean = true,
    public co_benefit?: string,
    public co_benefit_group?: string,
    public sub_co_benefit?: string,
    public optionSelected?: boolean
  ) {
    this.setOptionSelected();
  }

  setOptionSelected(): void {
    if (!this.optionSelected) {
      let { negative, neutral } = this;
      this.optionSelected = !!negative ? this.radioOptions[1].value :
        !!neutral ? this.radioOptions[2].value :
          this.radioOptions[0].value;
    }
  }

  setBooleanValues(): void {
    this.positive = this.optionSelected === this.radioOptions[0].value ? true : false;
    this.negative = this.optionSelected === this.radioOptions[1].value ? true : false;
    this.neutral = this.optionSelected === this.radioOptions[2].value ? true : false;
  }
};

export interface IPractice {
  id?: number;
  opportunity_id?: number;
  practice_description?: string;
  deleted?: boolean;

  delete(): void;
};

export class Practice implements IPractice {
  static create(args: any) {
    const {
      id, opportunity_id, practice_description,
      deleted
    } = args;
    return new Practice(
      id, opportunity_id, practice_description,
      deleted
    );
  }
  static createWithOppId(opportunityId: number) {

    return new Practice(
      undefined, opportunityId
    );
  }

  constructor(
    public id?: number,
    public opportunity_id?: number,
    public practice_description?: string,
    public deleted?: boolean
  ) { }

  delete(): void {
    this.deleted = true;
  }

  get practiceDescription() {
    return this.practice_description ? escapeStyle(this.practice_description) : "";
  }
}

export interface IReferenceLink {
  id?: number;
  opportunity_id?: number;
  description?: string;
  url?: string,
  document_file_name?: string;
  sort_order?: number;
  deleted?: boolean;
  linkType?: string;

  optionSelected?: boolean;
  radioOptions: string[];

  delete(): void;
  checkSortOrder(): void;
};

export class ReferenceLink implements IReferenceLink {
  static create(args: any) {
    const {
      id, opportunity_id, description,
      url, document_file_name,
      sort_order,
      deleted, linkType
    } = args;
    return new ReferenceLink(
      id, opportunity_id, description,
      url, document_file_name, sort_order,
      deleted, linkType
    );
  }
  static createWithOppId(opportunityId: number) {

    return new ReferenceLink(
      undefined, opportunityId
    );
  }

  // public linkType: string = 'URL';
  public radioOptions: string[] = ["URL", "Document"];

  constructor(
    public id?: number,
    public opportunity_id?: number,
    public description?: string,
    public url?: string,
    public document_file_name?: string,
    public sort_order?: number,
    public deleted?: boolean,
    public linkType?: string
  ) {
    if (!this.linkType) {
      this.linkType = !this.document_file_name ? 'URL' : 'Document';
    }
  }

  delete(): void {
    this.deleted = true;
  }

  checkSortOrder(): void {
    if (!!this.sort_order === false) {
      delete this.sort_order;
    }
  }
}


export interface IResourceOpportunity {
  id?: number;
  opportunity_id?: number;
  resource_id?: number;
};

export class ResourceOpportunity implements IResourceOpportunity {
  static create(args: any) {
    const { id, opportunity_id, resource_id, title, short_description } = args;
    return new ResourceOpportunity(id, opportunity_id, resource_id, title, short_description);
  }
  static createWithOppId(opportunityId: number) {
    return new ResourceOpportunity(
      undefined, opportunityId, undefined
    );
  }

  static createWithResId(resourceId: number) {
    return new ResourceOpportunity(
      undefined, undefined, resourceId
    );
  }

  constructor(
    public id?: number,
    public opportunity_id?: number,
    public resource_id?: number,
    public title?: string,
    public short_description?: string
  ) { }
}

export interface ICasiControl {
  db_major_version?: number;
  db_minor_version?: number;
  app_base_url?: string;
  param_company_name?: string;
  param_entity_name?: string;
  param_company_address?: string;
  param_abn?: string;
  param_acn?: string;
  param_primary_email?: string;
  param_additional_email?: string;
  param_website_url?: string;
  param_primary_contact_number?: number;
  param_additional_contact_number?: number;
  param_product_name?: string;
  review_opportunity_email?: string;
  review_opportunity_email_subject?: string;
};

export interface ICasiControlImage {
  id?: number;
  param_primary_image?: string;
  param_additional_image?: string;
  param_reply_to_email?: string;
  param_reset_password_bcc?: string;
};

export class CasiControl implements ICasiControl {
  static create(args: any) {
    const {
      db_major_version, db_minor_version, app_base_url,
      param_company_name, param_entity_name,
      param_company_address, param_abn, param_acn,
      param_primary_email, param_additional_email,
      param_website_url, param_primary_contact_number,
      param_additional_contact_number, param_product_name,
      review_opportunity_email, review_opportunity_email_subject
    } = args;
    return new CasiControl(
      db_major_version, db_minor_version, app_base_url,
      param_company_name, param_entity_name,
      param_company_address, param_abn, param_acn,
      param_primary_email, param_additional_email,
      param_website_url, param_primary_contact_number,
      param_additional_contact_number, param_product_name,
      review_opportunity_email, review_opportunity_email_subject
    );
  }

  constructor(
    public db_major_version?: number,
    public db_minor_version?: number,
    public app_base_url?: string,
    public param_company_name?: string,
    public param_entity_name?: string,
    public param_company_address?: string,
    public param_abn?: string,
    public param_acn?: string,
    public param_primary_email?: string,
    public param_additional_email?: string,
    public param_website_url?: string,
    public param_primary_contact_number?: number,
    public param_additional_contact_number?: number,
    public param_product_name?: string,
    public review_opportunity_email?: string,
    public review_opportunity_email_subject?: string
  ) {
  }
}

export class CasiControlWithParams implements ICasiControl, ICasiControlImage {
  static create(args: any) {
    const {
      id,
      db_major_version, db_minor_version, app_base_url,
      param_company_name, param_entity_name,
      param_company_address, param_abn, param_acn,
      param_primary_email, param_additional_email,
      param_website_url, param_primary_contact_number,
      param_additional_contact_number, param_product_name,
      param_primary_image, param_additional_image,
      param_reply_to_email, param_reset_password_bcc,
      review_opportunity_email, review_opportunity_email_subject
    } = args;
    return new CasiControlWithParams(
      id,
      db_major_version, db_minor_version, app_base_url,
      param_company_name, param_entity_name,
      param_company_address, param_abn, param_acn,
      param_primary_email, param_additional_email,
      param_website_url, param_primary_contact_number,
      param_additional_contact_number, param_product_name,
      param_primary_image, param_additional_image,
      param_reply_to_email, param_reset_password_bcc,
      review_opportunity_email, review_opportunity_email_subject
    );
  }

  constructor(
    public id?: number,
    public db_major_version?: number,
    public db_minor_version?: number,
    public app_base_url?: string,
    public param_company_name?: string,
    public param_entity_name?: string,
    public param_company_address?: string,
    public param_abn?: string,
    public param_acn?: string,
    public param_primary_email?: string,
    public param_additional_email?: string,
    public param_website_url?: string,
    public param_primary_contact_number?: number,
    public param_additional_contact_number?: number,
    public param_product_name?: string,
    public param_primary_image?: string,
    public param_additional_image?: string,
    public param_reply_to_email?: string,
    public param_reset_password_bcc?: string,
    public review_opportunity_email?: string,
    public review_opportunity_email_subject?: string
  ) {
  }
}

export interface IResource {
  id?: number;
  title?: string;
  description?: string;
  url?: string,
  document_file_name?: string;
  key_words?: string;
  deleted?: boolean;
  linkType?: string;
  opportunity_goals?: any[];
  industries?: any[];

  optionSelected?: boolean;
  radioOptions: string[];

  _goalList: CommonGoal[];
  _industryList: CommonIndustry[];

  delete(): void;
  setLists(): void;
};

export class Resource implements IResource {
  static create(args: any) {
    const {
      id, title, description,
      url, document_file_name,
      key_words,
      deleted, linkType, opportunity_goals, industries, opportunities
    } = args;
    return new Resource(
      [], [],
      id, title, description,
      url, document_file_name, key_words,
      deleted, linkType, opportunity_goals, industries, opportunities
    );
  }

  static createWithLists(args: any, goalList: CommonGoal[],
    industryList: CommonIndustry[]) {
    const {
      id, title, description,
      url, document_file_name,
      key_words,
      deleted, linkType, opportunity_goals, industries, opportunities
    } = args;
    return new Resource(
      goalList, industryList,
      id, title, description,
      url, document_file_name, key_words,
      deleted, linkType, opportunity_goals, industries, opportunities
    );
  }

  // public linkType: string = 'URL';
  public radioOptions: string[] = ["URL", "Document"];

  _goalList: CommonGoal[] = [];
  _industryList: CommonIndustry[] = [];

  goalListDisplay: CommonGoal[] = [];
  industryListDisplay: CommonIndustry[] = [];

  constructor(
    goalList?: CommonGoal[],
    industryList?: CommonIndustry[],
    public id?: number,
    public title?: string,
    public description: string = '',
    public url: string = '',
    public document_file_name?: string,
    public key_words?: string,
    public deleted?: boolean,
    public linkType?: string,
    public opportunity_goals?: any[],
    public industries?: any[],
    public opportunities?: number

  ) {
    if (!this.linkType) {
      this.linkType = !this.document_file_name ? 'URL' : 'Document';
    }
    if (goalList) {
      this._goalList = goalList;
    }
    if (industryList) {
      this._industryList = industryList;
    }
    this.goalListDisplay = this.goalList;
    this.industryListDisplay = this.industryList;
  }

  delete(): void {
    this.deleted = true;
  }

  get goalList(): CommonGoal[] {
    let returning = [...this._goalList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.opportunity_goals, "id"), r.id) > -1;
    }
    return returning;
  }
  set goalList(list: CommonGoal[]) {
    this.opportunity_goals = _.map(_.filter(list, 'checked'),
      d => {
        let { id, goal } = d;
        return { id, goal };
      })
  }

  get industryList(): CommonIndustry[] {
    let returning = [...this._industryList];
    for (let r of returning) {
      r.checked = _.indexOf(_.map(this.industries, "id"), r.id) > -1;
    }
    return returning;
  }
  set industryList(list: CommonIndustry[]) {
    this.industries = _.map(_.filter(list, 'checked'),
      d => {
        let { id, description } = d;
        return { id, description };
      })
  }

  setLists(): void {
    this.goalList = this.goalListDisplay;
    this.industryList = this.industryListDisplay;
  }
}

export interface IControlHost {
  db_major_version?: number;
  db_minor_version?: number;
  app_base_url?: string;
  param_company_name?: string,
  param_entity_name?: string;
  param_company_address?: string;
  param_abn?: string;
  param_acn?: string;
  param_primary_email?: string;
  param_additional_email?: string;
  param_website_url?: string;
  param_primary_contact_number?: string;
  param_additional_contact_number?: string;
  param_product_name?: string
};

export class ControlHost implements IControlHost {
  static create(args: any) {
    const {
      db_major_version, db_minor_version, app_base_url,
      param_company_name, param_entity_name,
      param_company_address,
      param_abn, param_acn,
      param_primary_email,
      param_additional_email,
      param_website_url,
      param_primary_contact_number,
      param_additional_contact_number,
      param_product_name
    } = args;
    return new ControlHost(
      db_major_version, db_minor_version, app_base_url,
      param_company_name, param_entity_name, param_company_address,
      param_abn, param_acn,
      param_primary_email,
      param_additional_email,
      param_website_url,
      param_primary_contact_number,
      param_additional_contact_number,
      param_product_name
    );
  }


  constructor(
    public db_major_version?: number,
    public db_minor_version?: number,
    public app_base_url?: string,
    public param_company_name?: string,
    public param_entity_name?: string,
    public param_company_address?: string,
    public param_abn?: string,
    public param_acn?: string,
    public param_primary_email?: string,
    public param_additional_email?: string,
    public param_website_url?: string,
    public param_primary_contact_number?: string,
    public param_additional_contact_number?: string,
    public param_product_name?: string
  ) {
  }

};

export interface ILevelDropDownTree {
  text: string;
  items: any[]
};

export class LevelDropDownTree implements ILevelDropDownTree {
  constructor(
    public text: string,
    public items: any[],
    public id: number | null
  ) { }
};

export interface IDropDownTreeItem {
  text: string;
  id: number;
  co_benefit: string;
  co_benefit_group: string;
};

export class DropDownTreeItem implements IDropDownTreeItem {
  constructor(
    public text: string,
    public id: number,
    public co_benefit: string,
    public co_benefit_group: string
  ) { }
}


export interface IOptions {
  id: number;
  icon_name?: string;
  description?: string;
};

export class Options implements IOptions {
  static create(args: any) {
    const { id, icon_name, description } = args;
    return new Options(id, icon_name, description);
  }

  public checked: boolean;
  constructor(
    public id: number,
    public icon_name?: string,
    public description?: string
  ) {
    this.checked = false;
  }
};

export interface IOpportunityOptions {
  title?: string;
  field?: string;
  icon_name?: string;
  options?: Options[];
  isOpen?: boolean;
};

export interface IResourceOptions {
  title?: string;
  field?: string;
  icon_name?: string;
  options?: Options[];
  isOpen?: boolean;
};

export interface IResourceSearchOpportunity {
  title?: string;
  opportunity_id?: number;
}

export class OpportunityOptions implements IOpportunityOptions {
  static create(args: any) {
    const { title, field, icon_name, options } = args;
    return new OpportunityOptions(title, field, icon_name, options);
  }

  public filterAll: boolean;
  public isOpen: boolean;
  constructor(
    public title?: string,
    public field?: string,
    public icon_name?: string,
    public options?: Options[]
  ) {
    this.filterAll = false;
    this.isOpen = false;
  }

};

export class ResourceOptions implements IResourceOptions {
  static create(args: any) {
    const { title, field, icon_name, options } = args;
    return new ResourceOptions(title, field, icon_name, options);
  }

  public filterAll: boolean;
  public isOpen: boolean;
  constructor(
    public title?: string,
    public field?: string,
    public icon_name?: string,
    public options?: Options[]
  ) {
    this.filterAll = false;
    this.isOpen = false;
  }

};

export interface IOpportunityParams {
  search?: string;
};

export interface IResourceParams {
  search?: string;
};

export class OpportunityParams implements IOpportunityParams {
  static create(args: any) {
    const { search } = args;
    return new OpportunityParams(search);
  }

  public filters: any;
  constructor(
    public search?: string,
  ) {
    this.filters = {};
  }

  addFilter(option?: OpportunityOptions) {
    this.filters[option?.field as any] = {
      filterAll: false,
      ids: []
    };
  }

  removeFilter(option?: OpportunityOptions) {
    delete this.filters[option?.field as any];
  }
};

export class ResourceParams implements IResourceParams {
  static create(args: any) {
    const { search } = args;
    return new ResourceParams(search);
  }

  public filters: any;
  constructor(
    public search?: string,
  ) {
    this.filters = {};
  }

  addFilter(option?: ResourceOptions) {
    this.filters[option?.field as any] = {
      filterAll: false,
      ids: []
    };
  }

  removeFilter(option?: ResourceOptions) {
    delete this.filters[option?.field as any];
  }
};

export interface IIconNameish {
  icon_name: string;
};

export interface IDescriptive {
  description: string;
}

export interface IOpportunityGoal extends IIdish, IIconNameish {
  goal: string;
}

export interface IIndustry extends IIdish, IIconNameish, IDescriptive { };
export interface IOpportunityResource extends IDescriptive {
  url: string,
  title: string,
  resource_id: number,
  document_file_name: string
};
export interface IOpportunityPractice extends IIdish, IDescriptive {
  practice_description: string;
};
export interface IOpportunityReference extends IIdish, IDescriptive {
  url: string;
};

export interface IStates extends IIdish, IDescriptive { };
export interface IBenefitTypes extends IIdish, IDescriptive { };

export interface ICoBenefits extends IIconNameish {
  co_benefit: string;
  neutral_qty: number;
  negative_qty: number;
  positive_qty: number;
}

export interface IOpportunityOutcome {
  group?: string;
  outcome?: string;
  negative?: boolean;
  positive?: boolean;
  neutral?: boolean;
  summary?: string;
  detailed_outcome?: string;
  icon_name?: string;
}

export class OpportunityOutcome implements IOpportunityOutcome {
  static create(args: any) {
    const { group, outcome, negative, positive, neutral, summary, detailed_outcome, icon_name } = args;
    return new OpportunityOutcome(group, outcome, negative, positive, neutral, summary, detailed_outcome, icon_name);
  }

  constructor(
    public group?: string,
    public outcome?: string,
    public negative?: boolean,
    public positive?: boolean,
    public neutral?: boolean,
    public summary?: string,
    public detailed_outcome?: string,
    public icon_name?: string
  ) {
  }
}

export interface IDiscoverOpportunitySearchOutcomes {
  id?: number;
  title?: string;
  short_description?: string;
  full_description?: string;
  opportunity_goals?: [IOpportunityGoal],
  classification_id?: number;
  classification_description?: string;
  classification_icon_name?: string;
  industries?: [IIndustry];
  states?: [IStates];
  organisation_name?: string;
  organisation_url?: string;
  service_providers?: string;
  benefit_types?: [any];
  benefits?: string;
  negatives?: string;
  risks?: string;
  costs?: string;
  attribution?: string;
  funding_scheme_start?: string;
  funding_scheme_end?: string;
  potential_financial_benefits?: string;
  organisation_contact_email?: string;
  elegibility_json?: [any];
  elegibility_text?: string;
  co_benefits?: [ICoBenefits];
  opportunity_resources?: [IOpportunityResource];
  opportunity_practices?: [IOpportunityPractice];
  opportunity_reference_links?: [IOpportunityReference];
  opportunity_outcome?: [IOpportunityOutcome];
  total_ranking?: number;
  demonstrated_financial_benefit?: number;
  demonstrated_risks?: number;
  certification_accreditation_scheme?: number;
  anz_iso_certification?: number;
  demonstrated_access_to_markets?: number;
  public_information?: number;
  information_quality?: number;
  demonstrated_non_financial_benefits?: number;
  demonstrated_costs?: number;

  organisationAndTitle: string;
  concatIndustries?: string;
  stateList?: string;
};

export interface IDiscoverResourceSearchOutcomes {
  id?: number;
  title?: string;
  description?: string;
  url?: string;
  document_file_name?: string;
  opportunity_goals?: [IOpportunityGoal],
  industries?: [IIndustry];
  opportunities?: [IResourceSearchOpportunity];
  opportunitesList?: string;

};

export class DiscoverOpportunitySearchOutcomes implements IDiscoverOpportunitySearchOutcomes {

  static create(args: any) {
    const {
      id, title, short_description,
      full_description, opportunity_goals,
      classification_id, classification_description, classification_icon_name,
      industries, states,
      organisation_name, organisation_url,
      service_providers,
      benefit_types, benefits,
      negatives, risks, costs,
      attribution, funding_scheme_start, funding_scheme_end,
      potential_financial_benefits, organisation_contact_email,
      elegibility_json, elegibility_text,
      co_benefits, opportunity_resources, opportunity_practices, opportunity_reference_links, opportunity_outcome, total_ranking,
      demonstrated_financial_benefit,
      demonstrated_risks,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      demonstrated_non_financial_benefits,
      demonstrated_costs
    } = args;
    return new DiscoverOpportunitySearchOutcomes(
      id,
      opportunity_goals, industries, states,
      benefit_types,
      elegibility_json, co_benefits,
      opportunity_resources, opportunity_practices, opportunity_reference_links, opportunity_outcome,
      title, short_description, full_description,
      classification_id, classification_description, classification_icon_name,
      organisation_name, organisation_url,
      service_providers, benefits,
      negatives, risks, costs, attribution,
      funding_scheme_start, funding_scheme_end,
      potential_financial_benefits, organisation_contact_email,
      elegibility_text,
      total_ranking,
      demonstrated_financial_benefit,
      demonstrated_risks,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      demonstrated_non_financial_benefits,
      demonstrated_costs
    );
  }


  constructor(
    public id?: number,
    public opportunity_goals?: [IOpportunityGoal],
    public industries?: [IIndustry],
    public states?: [IStates],
    public benefit_types?: [any],
    public elegibility_json?: [any],
    public co_benefits?: [ICoBenefits],
    public opportunity_resources?: [IOpportunityResource],
    public opportunity_practices?: [IOpportunityPractice],
    public opportunity_reference_links?: [IOpportunityReference],
    public opportunity_outcome?: [IOpportunityOutcome],
    public title?: string,
    public short_description?: string,
    public full_description?: string,
    public classification_id?: number,
    public classification_description?: string,
    public classification_icon_name?: string,
    public organisation_name?: string,
    public organisation_url?: string,
    public service_providers?: string,
    public benefits?: string,
    public negatives?: string,
    public risks?: string,
    public costs?: string,
    public attribution?: string,
    public funding_scheme_start?: string,
    public funding_scheme_end?: string,
    public potential_financial_benefits?: string,
    public organisation_contact_email?: string,
    public elegibility_text?: string,
    public total_ranking?: number,
    public demonstrated_financial_benefit?: number,
    public demonstrated_risks?: number,
    public certification_accreditation_scheme?: number,
    public anz_iso_certification?: number,
    public demonstrated_access_to_markets?: number,
    public public_information?: number,
    public information_quality?: number,
    public demonstrated_non_financial_benefits ?: number,
    public demonstrated_costs?: number
  ) {
  }

  get elegibility(): string {
    if(this.elegibility_text)
      return escapeStyle(this.elegibility_text);
    else return "";
  }

  get shortDescription(): string {
    if(this.short_description)
      return escapeStyle(this.short_description);
    else return "";
  }

  get organisationAndTitle(): string {
    if (this.organisation_name && this.organisation_url) {
      return `${this.organisation_name}
      ${this.organisation_url}`;
    }
    return '';
  };

  get concatIndustries(): string | undefined {
    let toJoin = this.industries?.map(industry => {
      return `<img src="${APP_CONFIG.iconBaseDir}${industry.icon_name}${APP_CONFIG.iconEnding}" style="max-width: 70px; min-width: 45px;cursor: pointer;"><br>
      <span>${industry.description}</span>`;
    })
    return toJoin?.join('<br>');
  }

  get stateList(): string | undefined {
    if (this.states) {
      let toJoin = this.states?.map(state => state.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get industryList(): string | undefined {
    if (this.industries) {
      let toJoin = this.industries?.map(i => i.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get goalList(): string | undefined {
    if (this.opportunity_goals) {
      let toJoin = this.opportunity_goals?.map(i => i.goal).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get coBenefitList(): string | undefined {
    if (this.co_benefits) {
      let toJoin = this.co_benefits?.map(i => i.co_benefit).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get resourceList(): string | undefined {
    if (this.opportunity_resources) {
      let toJoin = this.opportunity_resources?.map(i => i.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get eligibilityText(): string | undefined {
    const parser = new DOMParser();
    if(this.elegibility_text) {
      const doc = parser.parseFromString(this.elegibility_text, 'text/html');
      return doc.body.textContent || "";
    } else
      return "";

  }

};

export class DiscoverResourceSearchOutcomes implements IDiscoverResourceSearchOutcomes {

  static create(args: any) {
    const {
      id, title, description,
      url, document_file_name,
      opportunity_goals,
      industries, opportunities
    } = args;
    return new DiscoverResourceSearchOutcomes(
      id,
      opportunity_goals, industries, opportunities,
      title, description,
      url, document_file_name);
  }

  constructor(
    public id?: number,
    public opportunity_goals?: [IOpportunityGoal],
    public industries?: [IIndustry],
    public opportunities?: [IResourceSearchOpportunity],
    public title?: string,
    public description?: string,
    public url?: string,
    public document_file_name?: string,
  ) { }

  get opportunitesList(): string | undefined {
    if (this.opportunities) {
      let toJoin = this.opportunities?.map(o => o.title).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get industryList(): string | undefined {
    if (this.industries) {
      let toJoin = this.industries?.map(i => i.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get goalList(): string | undefined {
    if (this.opportunity_goals) {
      let toJoin = this.opportunity_goals?.map(i => i.goal).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

};

export interface IOpportunityReview {
  id?: number;
  reviewed_by_user_id?: number;
  reviewed_by_user?: string;
  reviewed_by_user_email?: string;
  review_comment?: string;
  requested_on?: string;
  opportunity_id?:number;
  title?: string;
  short_description?: string;
  full_description?: string;
  opportunity_goals?: any[];
  classification_id?: number;
  industries?: any[];
  states?: any[];
  organisation_name?: string;
  organisation_url?: string;
  service_providers?: string;
  benefit_types?: any[];
  benefits?: string;
  negatives?: string;
  risks?: string;
  costs?: string;
  attribution?: string;
  funding_scheme_start?: string;
  funding_scheme_end?: string;
  potential_financial_benefits?: string;
  updated_by_user_id?: number;
  updated_by_user?: string;
  updated_by_user_email?: string;
  updated_on?: string;
  review_complete?: boolean;
  demonstrated_financial_benefit?: number;
  certification_accreditation_scheme?: number;
  anz_iso_certification?: number;
  demonstrated_access_to_markets?: number;
  public_information?: number;
  information_quality?: number;
  organisation_contact_email?: string;
  elegibility_json?: any[];
  elegibility_text?: string;
  demonstrated_risks?: number;
  demonstrated_costs?: number;
  demonstrated_non_financial_benefits?: number;

  complete(): void;
};

export class OpportunityReview implements IOpportunityReview {
  static create(args: any) {
    const {
      id,
      reviewed_by_user_id,
      reviewed_by_user,
      reviewed_by_user_email,
      review_comment,
      requested_on,
      opportunity_id,
      title,
      short_description,
      full_description,
      opportunity_goals,
      classification_id,
      industries,
      states,
      organisation_name,
      organisation_url,
      service_providers,
      benefit_types,
      benefits,
      negatives,
      risks,
      costs,
      attribution,
      funding_scheme_start,
      funding_scheme_end,
      potential_financial_benefits,
      updated_by_user_id,
      updated_by_user,
      updated_by_user_email,
      updated_on,
      review_complete,
      demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      organisation_contact_email,
      elegibility_json, elegibility_text,
      demonstrated_risks, demonstrated_costs,
      demonstrated_non_financial_benefits,
      resources
    } = args;

    return new OpportunityReview(
      id,
      reviewed_by_user_id,
      reviewed_by_user,
      reviewed_by_user_email,
      review_comment,
      requested_on,
      opportunity_id,
      title,
      short_description,
      full_description,
      opportunity_goals,
      classification_id,
      industries,
      states,
      organisation_name,
      organisation_url,
      service_providers,
      benefit_types,
      benefits,
      negatives,
      risks,
      costs,
      attribution,
      funding_scheme_start,
      funding_scheme_end,
      potential_financial_benefits,
      updated_by_user_id,
      updated_by_user,
      updated_by_user_email,
      updated_on,
      review_complete,
      demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      organisation_contact_email,
      elegibility_json, elegibility_text,
      demonstrated_risks, demonstrated_costs,
      demonstrated_non_financial_benefits,
      resources
    );
  }

  constructor(
    public id?: number,
    public reviewed_by_user_id?: number,
    public reviewed_by_user?: string,
    public reviewed_by_user_email?: string,
    public review_comment?: string,
    public requested_on?: string,
    public opportunity_id?: number,
    public title?: string,
    public short_description?: string,
    public full_description?: string,
    public opportunity_goals?: any[],
    public classification_id?: number,
    public industries?: any[],
    public states?: any[],
    public organisation_name?: string,
    public organisation_url?: string,
    public service_providers?: string,
    public benefit_types?: any[],
    public benefits?: string,
    public negatives?: string,
    public risks?: string,
    public costs?: string,
    public attribution?: string,
    public funding_scheme_start?: string,
    public funding_scheme_end?: string,
    public potential_financial_benefits?: string,
    public updated_by_user_id?: number,
    public updated_by_user?: string,
    public updated_by_user_email?: string,
    public updated_on?: string | undefined,
    public review_complete?: boolean,
    public co_benefits?: string,
    public demonstrated_financial_benefit?: number,
    public certification_accreditation_scheme?: number,
    public anz_iso_certification?: number,
    public demonstrated_access_to_markets?: number,
    public public_information?: number,
    public information_quality?: number,
    public organisation_contact_email?: string,
    public elegibility_json?: any[],
    public elegibility_text?: string,
    public demonstrated_risks?: number,
    public demonstrated_costs?: number,
    public demonstrated_non_financial_benefits?: number
  ) {
    this.updated_on = this.updated_on ? moment(updated_on).format("DD/MM/YYYY") : undefined;
    this.requested_on = this.requested_on ? moment(requested_on).format("DD/MM/YYYY") : undefined;
    this.reviewed_by_user = (!this.reviewed_by_user || this.reviewed_by_user.match(/^ *$/) !== null) ? undefined : this.reviewed_by_user;
  }

  complete() {
    this.review_complete = true;
  }
};


export interface IReviewOpportunity {
  opportunity_review_id?: number;
  id?: number;
  opportunity_review_title?: string;
  title?: string;
  opportunity_review_short_description?: string;
  short_description?: string;
  opportunity_review_full_description?: string;
  full_description?: string;
  opportunity_goals?: [IOpportunityGoal],
  classification_id?: number;
  classification_description?: string;
  classification_icon_name?: string;
  industries?: [IIndustry];
  states?: [IStates];
  state_list?: string;
  opportunity_review_organisation_name?: string;
  organisation_name?: string;
  opportunity_review_organisation_url?: string;
  organisation_url?: string;
  opportunity_review_service_providers?: string;
  service_providers?: string;
  opportunity_review_benefit_types?: [any];
  benefit_types?: [any];
  opportunity_review_benefits?: string;
  benefits?: string;
  opportunity_review_negatives?: string;
  negatives?: string;
  opportunity_review_risks?: string;
  risks?: string;
  opportunity_review_costs?: string;
  costs?: string;
  opportunity_review_attribution?: string;
  attribution?: string;
  opportunity_review_funding_scheme_start?: string;
  funding_scheme_start?: string;
  opportunity_review_funding_scheme_end?: string;
  funding_scheme_end?: string;
  opportunity_review_potential_financial_benefits?: string;
  potential_financial_benefits?: string;
  opportunity_review_organisation_contact_email?: string;
  organisation_contact_email?: string;
  opportunity_review_elegibility_json?: [any];
  elegibility_json?: [any];
  opportunity_review_elegibility_text?: string;
  elegibility_text?: string;
  co_benefits?: [ICoBenefits];
  opportunity_resources?: [IOpportunityResource];
  opportunity_practices?: [IOpportunityPractice];
  opportunity_reference_links?: [IOpportunityReference];
  opportunity_outcome?: [IOpportunityOutcome];
  total_ranking?: number;
  organisationAndTitle: string;
  reviewOrganisationAndTitle: string;
  concatIndustries?: string;
  stateList?: string;
  review_complete?: boolean;
  review_comment?: string;
}


export class ReviewOpportunity implements IReviewOpportunity {

  static create(args: any) {
    const {
      opportunity_review_id,
      id,
      opportunity_goals,
      industries,
      states,
      opportunity_review_benefit_types,
      benefit_types,
      opportunity_review_elegibility_json,
      elegibility_json,
      co_benefits,
      opportunity_resources,
      opportunity_practices,
      opportunity_reference_links,
      opportunity_outcome,
      opportunity_review_title,
      title,
      opportunity_review_short_description,
      short_description,
      opportunity_review_full_description,
      full_description,
      classification_id,
      classification_description,
      classification_icon_name,
      opportunity_review_organisation_name,
      organisation_name,
      opportunity_review_organisation_url,
      organisation_url,
      opportunity_review_service_providers,
      service_providers,
      opportunity_review_benefits,
      benefits,
      opportunity_review_negatives,
      negatives,
      opportunity_review_risks,
      risks,
      opportunity_review_costs,
      costs,
      opportunity_review_attribution,
      attribution,
      opportunity_review_funding_scheme_start,
      funding_scheme_start,
      opportunity_review_funding_scheme_end,
      funding_scheme_end,
      opportunity_review_potential_financial_benefits,
      potential_financial_benefits,
      opportunity_review_organisation_contact_email,
      organisation_contact_email,
      opportunity_review_elegibility_text,
      elegibility_text,
      total_ranking, review_complete,
      review_comment
    } = args;
    return new ReviewOpportunity(
      opportunity_review_id,
      id,
      opportunity_goals,
      industries,
      states,
      opportunity_review_benefit_types,
      benefit_types,
      opportunity_review_elegibility_json,
      elegibility_json,
      co_benefits,
      opportunity_resources,
      opportunity_practices,
      opportunity_reference_links,
      opportunity_outcome,
      opportunity_review_title,
      title,
      opportunity_review_short_description,
      short_description,
      opportunity_review_full_description,
      full_description,
      classification_id,
      classification_description,
      classification_icon_name,
      opportunity_review_organisation_name,
      organisation_name,
      opportunity_review_organisation_url,
      organisation_url,
      opportunity_review_service_providers,
      service_providers,
      opportunity_review_benefits,
      benefits,
      opportunity_review_negatives,
      negatives,
      opportunity_review_risks,
      risks,
      opportunity_review_costs,
      costs,
      opportunity_review_attribution,
      attribution,
      opportunity_review_funding_scheme_start,
      funding_scheme_start,
      opportunity_review_funding_scheme_end,
      funding_scheme_end,
      opportunity_review_potential_financial_benefits,
      potential_financial_benefits,
      opportunity_review_organisation_contact_email,
      organisation_contact_email,
      opportunity_review_elegibility_text,
      elegibility_text,
      total_ranking,
      review_complete,
      review_comment
    );
  }


  constructor(
    public opportunity_review_id?: number,
    public id?: number,
    public opportunity_goals?: [IOpportunityGoal],
    public industries?: [IIndustry],
    public states?: [IStates],
    public opportunity_review_benefit_types?: [any],
    public benefit_types?: [any],
    public opportunity_review_elegibility_json?: [any],
    public elegibility_json?: [any],
    public co_benefits?: [ICoBenefits],
    public opportunity_resources?: [IOpportunityResource],
    public opportunity_practices?: [IOpportunityPractice],
    public opportunity_reference_links?: [IOpportunityReference],
    public opportunity_outcome?: [IOpportunityOutcome],
    public opportunity_review_title?: string,
    public title?: string,
    public opportunity_review_short_description?: string,
    public short_description?: string,
    public opportunity_review_full_description?: string,
    public full_description?: string,
    public classification_id?: number,
    public classification_description?: string,
    public classification_icon_name?: string,
    public opportunity_review_organisation_name?: string,
    public organisation_name?: string,
    public opportunity_review_organisation_url?: string,
    public organisation_url?: string,
    public opportunity_review_service_providers?: string,
    public service_providers?: string,
    public opportunity_review_benefits?: string,
    public benefits?: string,
    public opportunity_review_negatives?: string,
    public negatives?: string,
    public opportunity_review_risks?: string,
    public risks?: string,
    public opportunity_review_costs?: string,
    public costs?: string,
    public opportunity_review_attribution?: string,
    public attribution?: string,
    public opportunity_review_funding_scheme_start?: string,
    public funding_scheme_start?: string,
    public opportunity_review_funding_scheme_end?: string,
    public funding_scheme_end?: string,
    public opportunity_review_potential_financial_benefits?: string,
    public potential_financial_benefits?: string,
    public opportunity_review_organisation_contact_email?: string,
    public organisation_contact_email?: string,
    public opportunity_review_elegibility_text?: string,
    public elegibility_text?: string,
    public total_ranking?: number,
    public review_complete?: boolean,
    public review_comment?: string
  ) {}

  get elegibility(): string {
    if(this.elegibility_text)
      return escapeStyle(this.elegibility_text);
    else return "";
  }

  get fullDescription(): string {
    if(this.full_description)
      return escapeStyle(this.full_description);
    else return "";
  }

  get serviceProviders(): string {
    if(this.service_providers)
      return escapeStyle(this.service_providers);
    else return "";
  }

  get benefitEsc(): string {
    if(this.benefits)
      return escapeStyle(this.benefits);
    else return "";
  }

  get negativesEsc(): string {
    if(this.negatives)
      return escapeStyle(this.negatives);
    else return "";
  }

  get risksEsc(): string {
    if(this.risks)
      return escapeStyle(this.risks);
    else return "";
  }

  get costsEsc(): string {
    if(this.costs)
      return escapeStyle(this.costs);
    else return "";
  }

  get attributionEsc(): string {
    if(this.attribution)
      return escapeStyle(this.attribution);
    else return "";
  }

  get potentialFinancialBenefits(): string {
    if(this.potential_financial_benefits)
      return escapeStyle(this.potential_financial_benefits);
    else return "";
  }

  get opportunityReviewElegibility(): string {
    if(this.opportunity_review_elegibility_text)
      return escapeStyle(this.opportunity_review_elegibility_text);
    else return "";
  }

  get opportunityReviewFullDescription(): string {
    if(this.opportunity_review_full_description)
      return escapeStyle(this.opportunity_review_full_description);
    else return "";
  }

  get opportunityReviewServiceProviders(): string {
    if(this.opportunity_review_service_providers)
      return escapeStyle(this.opportunity_review_service_providers);
    else return "";
  }

  get opportunityReviewBenefit(): string {
    if(this.opportunity_review_benefits)
      return escapeStyle(this.opportunity_review_benefits);
    else return "";
  }

  get opportunityReviewNegatives(): string {
    if(this.opportunity_review_negatives)
      return escapeStyle(this.opportunity_review_negatives);
    else return "";
  }

  get opportunityReviewRisks(): string {
    if(this.opportunity_review_risks)
      return escapeStyle(this.opportunity_review_risks);
    else return "";
  }

  get opportunityReviewCosts(): string {
    if(this.opportunity_review_costs)
      return escapeStyle(this.opportunity_review_costs);
    else return "";
  }

  get opportunityReviewAttribution(): string {
    if(this.opportunity_review_attribution)
      return escapeStyle(this.opportunity_review_attribution);
    else return "";
  }

  get opportunityReviewPotentialFinancialBenefits(): string {
    if(this.opportunity_review_potential_financial_benefits)
      return escapeStyle(this.opportunity_review_potential_financial_benefits);
    else return "";
  }

  get organisationAndTitle(): string {
    if (this.organisation_name && this.organisation_url) {
      return `${this.organisation_name}
      ${this.organisation_url}`;
    }
    return '';
  };

  get reviewOrganisationAndTitle(): string {
    if (this.opportunity_review_organisation_name && this.opportunity_review_organisation_url) {
      return `${this.opportunity_review_organisation_name}
      ${this.opportunity_review_organisation_url}`;
    }
    return '';
  };

  get concatIndustries(): string | undefined {
    let toJoin = this.industries?.map(industry => {
      return `<img src="${APP_CONFIG.iconBaseDir}${industry.icon_name}${APP_CONFIG.iconEnding}" style="max-width: 70px; min-width: 45px;cursor: pointer;"><br>
      <span>${industry.description}</span>`;
    })
    return toJoin?.join('<br>');
  }

  get stateList(): string | undefined {
    if (this.states) {
      let toJoin = this.states?.map(state => state.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get industryList(): string | undefined {
    if (this.industries) {
      let toJoin = this.industries?.map(i => i.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get goalList(): string | undefined {
    if (this.opportunity_goals) {
      let toJoin = this.opportunity_goals?.map(i => i.goal).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get coBenefitList(): string | undefined {
    if (this.co_benefits) {
      let toJoin = this.co_benefits?.map(i => i.co_benefit).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get resourceList(): string | undefined {
    if (this.opportunity_resources) {
      let toJoin = this.opportunity_resources?.map(i => i.description).join(', ');
      return toJoin;
    } else {
      return '';
    }
  }

  get eligibilityText(): string | undefined {
    const parser = new DOMParser();
    if(this.elegibility_text) {
      const doc = parser.parseFromString(this.elegibility_text, 'text/html');
      return doc.body.textContent || "";
    } else
      return "";
  }

  get reviewEligibilityText(): string | undefined {
    const parser = new DOMParser();
    if(this.opportunity_review_elegibility_text) {
      const doc = parser.parseFromString(this.opportunity_review_elegibility_text, 'text/html');
      return doc.body.textContent || "";
    } else
      return "";
  }

};
