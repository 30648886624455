import { Component, Inject, NO_ERRORS_SCHEMA, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ActivatedRoute, RouterLink, RouterLinkActive } from "@angular/router";
import { CheckboxListComponent } from "../../common-components/checkbox-list/checkbox-list.component";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NotificationModule, NotificationService } from "@progress/kendo-angular-notification";
import { LabelModule } from "@progress/kendo-angular-label";
import { AvatarRounded, AvatarSize, LayoutModule } from "@progress/kendo-angular-layout";
import { Observable, Subscription } from "rxjs";
import { LoggerService } from "../../shared/logger/logger.service";
import { AuthTokenService } from "../../auth/auth-token.service";
import {
  DiscoverOpportunitySearchOutcomes,
  DiscoverResourceSearchOutcomes, Opportunity,
  ResourceOptions,
  ResourceParams
} from "../../models/models.lib";
import * as _ from 'lodash';
import { ResourceOptionsAccordionComponent } from "../options-accordion/resource-options-accordion.component";
import { ExcelModule, GridModule, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  distinct,
  filterBy,
  orderBy,
  process
} from '@progress/kendo-data-query';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { GridSize } from "@progress/kendo-angular-grid";
import { all } from '@awesome.me/kit-787edf9aa0/icons';

// import {
//   faTriangleExclamation,
//   faPlusCircle, faAddressCard,
//   faEdit, faTrash, faBan, faDownload, faEye, faSearch
// } from '@fortawesome/free-solid-svg-icons';
import { AppConfig, CONFIG_TOKEN } from '../../setup/config';
import { DiscoverResourcesService } from '../discover-resources.service';
import { SearchResourcesService } from '../search-resources.service';
import {WindowModule, WindowRef} from "@progress/kendo-angular-dialog";
import { FormModes } from "../../common/constants.lib";
import { DiscoverOpportunitiesService } from "../../discoverOpportunities/discover-opportunities.service";
import { ViewOpportunityComponent } from "../../discoverOpportunities/view-opportunity/view-opportunity.component";
import { HelpButtonComponent } from "../../admin/help-info/help-button/help-button.component";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { ClickLoggerDirective } from '../../directives/click-logger.directive';
import { HelpPopoverComponent } from '../../admin/help-info/help-popover/help-popover.component';
import {PDFViewerModule, PDFViewerTool} from "@progress/kendo-angular-pdfviewer";
import {FilenamePromptComponent} from "../../common-components/filename-prompt/filename-prompt.component";
import {CasiPdfViewerComponent} from "../../common-components/casi-pdf-viewer/casi-pdf-viewer.component";
import {IndicatorsModule} from "@progress/kendo-angular-indicators";
import {BrowserModule} from "@angular/platform-browser";

@Component({
  selector: 'casi-discover-resources',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    LayoutModule,
    ResourceOptionsAccordionComponent,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    SafeHtmlPipe,
    WindowModule,
    ViewOpportunityComponent,
    HelpButtonComponent,
    ClickLoggerDirective,
    ExcelModule,
    PDFViewerModule,
    HelpPopoverComponent,
    ClickLoggerDirective,
    FilenamePromptComponent,
    CasiPdfViewerComponent,
    IndicatorsModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './discover-resources.component.html',
  styleUrl: './discover-resources.component.scss'
})
export class DiscoverResourcesComponent implements OnInit, OnDestroy {
  public view: Observable<DiscoverResourceSearchOutcomes[]> = new Observable<DiscoverResourceSearchOutcomes[]>();
  public rawData: DiscoverResourceSearchOutcomes[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: DiscoverResourceSearchOutcomes[] = filterBy(this.rawData, this.filter);
  // public opportunity: DiscoverOpportunitySearchOutcomes = null as any;
  public opportunities: DiscoverOpportunitySearchOutcomes[] = [];

  public currentItem: DiscoverOpportunitySearchOutcomes = null as any;
  private windowRef: WindowRef | undefined;
  public printOpportunityOpen: boolean = false;
  public url: string = "";
  public downloadFilename = 'Download.pdf';
  public tools: PDFViewerTool[] = [
    "pager",
    "spacer",
    "zoomInOut",
    "zoom",
    "selection",
    "spacer",
    "search",
    // "open",
    "download",
    "print",
  ];

  form: FormGroup;
  private _subs: Subscription[] = [];
  public options: ResourceOptions[] = [];
  public params!: ResourceParams;
  public filterBy: string = "";
  public itemId: number = null as any;
  public viewOpportunityOpen: boolean = false;
  public test: string = "";

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSize = 10;

  //data is sorted by title from database
  public sort: SortDescriptor[] = [
   // { field: "id", dir: "asc" }
  ];

  public canCreate: boolean = false;
  public canUpdate: boolean = false;
  public canDestroy: boolean = false;

  public iconRoundness: AvatarRounded = "none";
  public iconSize: AvatarSize = 'large';

  public gridSize: GridSize = 'medium';

  @ViewChild("searchLogger") element: any;

  //public isLoading: boolean = false;

  constructor(private logger: LoggerService,
    public authTokenService: AuthTokenService,
    private route: ActivatedRoute,
    public dataService: DiscoverResourcesService,
    public searchService: SearchResourcesService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    public oService: DiscoverOpportunitiesService,
    @Inject(CONFIG_TOKEN) private config: AppConfig,

  ) {
    this.form = this.fb.group({ searchResource: [null, []] });
    this.params = ResourceParams.create({});
    faIcons.addIcons(...all);
    // faIcons.addIcons(
    //   faTriangleExclamation,
    //   faPlusCircle, faAddressCard, faEdit,
    //   faTrash, faSearch,
    //   faBan, faDownload, faEye
    // );

    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: [
          { field: "title", dir: "asc" },
          { field: "description", dir: "asc" }
        ]
      }).data
    };
    return result;
  }

  get f() {
    return this.form.controls;
  }

  get searchResource() {
    return this.form.get('searchResource');
  }

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.params);
    this.loadData();

    this._subs.push(this.route.data.subscribe(({ options }) => {
      this.options = _.map(options, o => ResourceOptions.create(o));
      this.logger.debug('ngOnInit: this.options: ', this.options);
    }));

    this._subs.push(this.searchService.subscribe((outcomes) => {
      this.logger.debug('ngOnInit:outcomes: ', outcomes);
      this.rawData = outcomes;
      this._loadData();
    }))

    this.route.params.subscribe(params => {
      this.logger.debug('ngOnInit:params:', params);
      this.filterBy = params['filterBy'];
      this.itemId = params['id'];
    });
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  buildImageLink(imageName: string): string {
    return `${this.config.iconBaseDir}${imageName}${this.config.iconEnding}`;
  }

  onViewClick(dataItem: any): void {
    this.logger.debug("onViewClick: ", dataItem);
  }

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }


  //#endregion

  public loadData(): void {
    this.logger.debug('loadData...', this.params);
    this.searchService.doSearch(this.params);
    this.element?.manualLog(`discoverResources:search:${JSON.stringify(this.params)}`)
  }

  public onChange(args: any): void {
    this.logger.debug('onChange...', args);
    // this.test = JSON.stringify(this.params)
    this.loadData();
  }

  private _loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  // viewOpportunity(id: number): void {
  //   this.logger.debug("viewOpportunity: ", id);
  //
  //   this.oService.exploreOpportunity(id)
  //     .then(resp => {
  //       this.viewOpportunityOpen = true;
  //       if (resp)
  //         this.opportunity = DiscoverOpportunitySearchOutcomes.create(resp[0]);
  //     })
  //     .catch(err => {
  //       this.logger.error('handleSaveAndClose:error: ', err);
  //     });
  // }

  viewOpportunities(dataItem: any): void {
   // this.isLoading = true;
    this.logger.debug("viewOpportunity: ", dataItem);
    this.currentItem = dataItem;
    this.dataService.exploreOpportunities(dataItem.id)
      .then(resp => {
        if (resp)
          this.opportunities = resp.map(r => DiscoverOpportunitySearchOutcomes.create(r));
        this.viewOpportunityOpen = true;
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
      })
     // .finally(() => this.isLoading = false)
  }

  public closeViewOpportunity(): void {
    this.logger.debug('closeViewOpportunity...');
    this.viewOpportunityOpen = false;
  }

  public closePrintOpportunity(): void {
    this.closePdfWindow();
  }

  printPDF(args: any): void {
    this.logger.debug("printPDF: ");
    const { url } = args;
    this.url = url;
    this.downloadFilename = `${(this.currentItem.title as string)}.pdf`;
    this.printOpportunityOpen = true;
  }

  public closeViewer(): void {
    this.closePdfWindow();
  }

  private closePdfWindow(): void {
    this.logger.debug('closePdfWindow...');
    this.closeWindowRef();
  }

  private closeWindowRef(): void {
    this.logger.debug('closeWindowRef...', this.windowRef);
    if (this.windowRef) {
      this.windowRef.close();
      this.windowRef = undefined;
    }
    this.printOpportunityOpen = false;
  }
}

