import { HttpInterceptorFn, HttpEventType } from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {LoadingService} from '../httpServices/loadingService';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { LoggerService } from '../shared/logger/logger.service';


export const spinnerInterceptor: HttpInterceptorFn = (req, next) => {
  //return next(req);
  const logger = inject(LoggerService);
  const _loading = inject(LoadingService);
  _loading.setLoading(true, req.url);
  return next(req)
      .pipe(catchError((err) => {
        _loading.setLoading(false, req.url);
        logger.error('http error', err);
        return throwError(() => new Error(err));
        //return err;
      }))
      .pipe(tap(event => {
        if (event.type === HttpEventType.Response) {
          logger.debug(req.url, 'returned a response with status', event.status);
          _loading.setLoading(false, req.url);
        }
      }));
};
