<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container-fluid gx-5">

  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <!--        <h3 style="text-align: left;" class="page-header">Discover Resources</h3>-->
      </div>

      <!-- <div *ngIf="isLoading">
        <kendo-spinner ></kendo-spinner>
      </div> -->

      <!-- moved to app component and all http requests-->
      
      <!-- <div class="k-block" *ngIf="isLoading" style="position: fixed; top: 50%; left: 50%;">
        <kendo-loader
          [type]="'converging-spinner'"
          [themeColor]="'primary'"
          [size]="'large'"></kendo-loader>
      </div> -->

      <form class="k-form-inline" style="padding: 0; margin-bottom: 40px">
        <fieldset>

          <hr style="width: 50px; border-top: 5px solid;">
          <!-- small screen version -->
          <div class="row row-nomargin d-block d-md-none">
            <div class="col-12">
              <h2>Resources</h2>
            </div>
          </div>
          <div class="row row-nomargin d-block d-md-none" style="margin-bottom: 10px;">
            <div class="col-12 d-flex" >
              <input type="text" id="searchResource" class="form-control" [(ngModel)]="params.search"
                name="searchResource" placeholder="Refine your search..." style="width: 80%">
              <button class="btn-transparent" (click)="loadData()" #searchLogger="casiclicklogger" casiClickLogger="false">
                <span style="cursor: pointer"><fa-icon icon="search" style="margin-left: 5px; font-size: 20px"></fa-icon></span>
              </button>
            </div>
            <label for="searchResource" class="form-label" style="text-align: left; padding-left: 10px;">Search for phrase or term</label>
          </div>

          <div style="display: flex">
            <div class="d-none d-md-block col-md-6">
              <h2>Resources</h2>
            </div>
            <div class="d-none d-md-block col-md-6">
              <div class="col-12 d-flex" style="margin-top: -40px;">
                <input type="text" id="searchResource" class="form-control" [(ngModel)]="params.search" name="searchResource" placeholder="Refine your search..." style="width: 80%">
                <button class="btn-transparent" (click)="loadData()" #searchLogger="casiclicklogger" casiClickLogger="false">
                  <span style="cursor: pointer"><fa-icon icon="search" style="margin-left: 5px; font-size: 20px"></fa-icon></span>
                </button>
              </div>
              <label for="searchResource" class="form-label" style="text-align: left; padding-left: 10px;">Search for phrase or term</label>
            </div>
          </div>




          <h4>Use the filters to only view resources of interest: <casi-help-popover style="display: inline-block;" [infoId]="25"></casi-help-popover></h4>

          <resource-options-accordion [items]="options" [output]="params" [filterBy]="filterBy" [itemId]="itemId" (onChange)="onChange($event)"></resource-options-accordion>
        </fieldset>
      </form>

      <h4>Browse {{gridData.length}} listed Resources based on selection:</h4>
      <div class="add-footer-margin">

        <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
          [groupable]="false" [resizable]="true" [filter]="filter" filterable="menu"
          (filterChange)="filterChange($event)" (sortChange)="sortChange($event)" [pageSize]="pageSize"
          [pageable]="true" [style.maxHeight.px]="2000" [size]="gridSize">

          <ng-template kendoGridToolbarTemplate position="top">
            <button kendoGridExcelCommand class="btn btn-primary">
              <fa-icon icon="download"></fa-icon> Export Data</button>
          </ng-template>

          <kendo-grid-column title="View Resource" [width]="70" [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">View Resource</span><casi-help-popover [infoId]="21" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <a *ngIf="dataItem.url" class="btn btn-stn-grid" href="{{dataItem.url}}" target="_blank" casiClickLogger="a[discoverResources:ViewResource:url:{{dataItem.url}}]">
                <fa-icon [icon]="['fat', 'memo-circle-info']"></fa-icon> <span style="margin-left: 10px;">View</span></a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="title" title="Title" [width]="180" [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Title</span><casi-help-popover [infoId]="22" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
              let-filterService="filterService">
              <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="description" title="Description" [width]="250"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Description</span><casi-help-popover [infoId]="23" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter" let-filterService="filterService">
              <div [innerHTML]="dataItem.description"></div>

              <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService" [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="industryList" title="Industries" [width]="80"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Industries</span><casi-help-popover [infoId]="17" [iconStyle]="'color: #d9b35b;'"
                [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let industry of dataItem.industries">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(industry.icon_name)">˝</kendo-avatar>
                  <div>{{industry.description}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="goalList" title="Project Goals" [width]="80"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Project Goals</span><casi-help-popover
                [infoId]="19" [iconStyle]="'color: #5d654d;'" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let item of dataItem.opportunity_goals">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(item.icon_name)">˝</kendo-avatar>
                  <div>{{item.goal}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="opportunitesList" title="Opportunities" [width]="80" [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Opportunities</span><casi-help-popover [infoId]="14" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                <button class="btn btn-stn-grid" (click)="viewOpportunities(dataItem)"
                        [disabled]="!dataItem.opportunities || dataItem.opportunities.length == 0" >{{dataItem.opportunities ? dataItem.opportunities.length : 0}}</button>
<!--                <div *ngFor="let item of dataItem.opportunities" style="cursor: pointer; margin-bottom:20px" (click)="viewOpportunity(item.opportunity_id)" [ngStyle]="{'text-decoration': 'underline', 'cursor': 'pointer'}">{{item.title}}</div>-->
              </div>
            </ng-template>
          </kendo-grid-column>
          <ng-template kendoPagerTemplate>
            <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
          </ng-template>

          <kendo-grid-excel fileName="Resources.xlsx" [fetchData]="allData"></kendo-grid-excel>
        </kendo-grid>

      </div>

    </div>
  </div>
</div>


<kendo-window *ngIf="viewOpportunityOpen" (close)="closeViewOpportunity()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">RESOURCE OPPORTUNITIES</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
<!--  @for (opportunity of opportunities; track opportunity; let  i = $index) {-->
<!--  <div>-->
<!--  <casi-view-opportunity [opportunity]="opportunity"  [hideCloseBtn]="true"></casi-view-opportunity>-->
<!--  <hr *ngIf="i < opportunities.length - 1">-->
<!--  </div>-->
<!--  }-->
  <div *ngFor="let opportunity of opportunities; index as i">
    <casi-view-opportunity [opportunity]="opportunity"  [hideCloseBtn]="true" (onPrint)="printPDF($event)" (onPrintClick)="closeViewer()"></casi-view-opportunity>
    <hr *ngIf="i < opportunities.length - 1">
  </div>
  <div class="d-grid d-md-flex justify-content-md-center">
    <button type="button" class="btn btn-secondary" (click)="closeViewOpportunity()" > Close</button>
  </div>
</kendo-window>

<kendo-window *ngIf="printOpportunityOpen" (close)="closePrintOpportunity()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">OPPORTUNITY DETAILS</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <kendo-pdfviewer style="height: 600px;" [saveFileName]="downloadFilename" [url]="url"> </kendo-pdfviewer>
  <div kendoDialogContainer></div>
</kendo-window>
