<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- Loading spinner -->
<div *ngIf="loading" class="k-block" >
  <kendo-loader
  [type]="'converging-spinner'"
  [themeColor]="'primary'"
  [size]="'large'"></kendo-loader>
</div>


<div style="display: flex; flex-direction: column; min-height: 100vh;">
  <app-header></app-header>
  <!-- <div class="dropdown">

  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div> -->
  <main class="main">

    <div class="content">
      <div class="left-side">
        <!-- <h1>Hello, {{ title }}</h1>
      <p>Congratulations! Your app is running. 🎉<fa-icon [icon]="faCoffee"></fa-icon></p> -->
        <!-- <p><fa-icon icon="coffee"></fa-icon></p>
      <p>Test Api Call: {{ testApiCall$ | async | json}}</p>
      <p>Health: {{ health$ | async | json}}</p> -->
      </div>
    </div>
  </main>

  <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
  <!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
  <!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
  <!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
  <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
  <!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
  <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

  <router-outlet></router-outlet>
  <app-footer style="margin-top: auto;"></app-footer>
</div>
