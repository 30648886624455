import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AppConfig, CONFIG_TOKEN } from './setup/config';
import { LoggerService } from './shared/logger/logger.service';
import { ApiService } from './api/api.service';
import { HttpClientModule } from '@angular/common/http';
import { Observable, Subscription, delay } from 'rxjs';
import { HeaderComponent } from './header/header.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCoffee, faPoo } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FooterComponent } from "./footer/footer.component";
import { environment } from '../environments/environment';
import { LoadingService } from './httpServices/loadingService';
import {IndicatorsModule} from "@progress/kendo-angular-indicators";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, RouterOutlet, RouterLink, RouterLinkActive,
    HttpClientModule, FontAwesomeModule,
    HeaderComponent, FooterComponent,
    MatSnackBarModule, IndicatorsModule
  ],
  providers: [HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  faCoffee = faCoffee;
  faPoo = faPoo;
  title = 'environer-ng';
  loadedFeature = 'recipe';
  loading: boolean = false;

  health$: Observable<any> = new Observable<any>();
  testApiCall$: Observable<any> = new Observable<any>();


  private _subs: Subscription[] = [];
  // private previousStatus: boolean = this.authTokenService.isLoggedIn;

  constructor(
    @Inject(CONFIG_TOKEN) private config: AppConfig,
    private logger: LoggerService,
    private apiService: ApiService,
    library: FaIconLibrary,
    private _loading: LoadingService
  ) {
    this.logger.log('constructor: config: %o', config);
    library.addIcons(faCoffee, faPoo);

    this.logger.log('Production mode: ', environment);
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.apiService.getCsrf();
    this.health$ = this.apiService.getHealth();
    this.listenToLoading();
    // this.testApiCall$ = this.apiService.testGet();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onNavigate(feature: string) {
    this.loadedFeature = feature;
  }

  listenToLoading(): void {
    this._loading.loadingSub
     .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

}
