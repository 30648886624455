import {
  Component,
  EventEmitter,
  Input,
  NO_ERRORS_SCHEMA,
  OnDestroy,
  OnInit,
  Output, Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {DialogsModule, WindowModule} from "@progress/kendo-angular-dialog";
import {NotificationModule, NotificationService} from "@progress/kendo-angular-notification";
import {GridModule, PagerModule, PagerPosition, PagerType} from "@progress/kendo-angular-grid";
import {InputsModule} from "@progress/kendo-angular-inputs";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {LabelModule} from "@progress/kendo-angular-label";
import {LayoutModule} from "@progress/kendo-angular-layout";
import {Observable, Subscription} from "rxjs";
import {CompositeFilterDescriptor, distinct, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {LoggerService} from "../../shared/logger/logger.service";
import {Opportunity, OpportunityReview} from "../../models/models.lib";
import {OpportunityReviewsService} from "../opportunity-reviews.service";
import {MulticheckFilterComponent} from "../../filters/multicheck-filter/multicheck-filter.component";
import {SendReviewComponent} from "../send-review/send-review.component";
import {faArrowUp, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MeService} from "../../auth/me.service";
import {AppEnities} from "../../common/constants.lib";
import {IntegrateReviewComponent} from "../integrate-review/integrate-review.component";
import {lockBodyScroll, unlockBodyScroll} from "../../common/utils.lib";

const { REVIEW } = AppEnities;

@Component({
  selector: 'casi-opportunity-reviews',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    RouterLink, RouterLinkActive,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule,
    MulticheckFilterComponent,
    SendReviewComponent,
    FontAwesomeModule,
    IntegrateReviewComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './opportunity-reviews.component.html',
  styleUrl: './opportunity-reviews.component.scss'
})
export class OpportunityReviewsComponent implements OnInit, OnDestroy {

  // @Input({ required: true }) opportunityId: number = 0;
  @Input({ required: true }) opportunity: Opportunity = null as any;
  @Output() onClose = new EventEmitter<void>();
  @Output() onAdd = new EventEmitter<any>();

  public view: Observable<OpportunityReview[]> = new Observable<OpportunityReview[]>();
  public rawData: OpportunityReview[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: OpportunityReview[] = filterBy(this.rawData, this.filter);

  public currentItem: OpportunityReview = new OpportunityReview();

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSize = 10;

  public addItemOpen: boolean = false;
  public confirmDeleteItem: boolean = false;
  public integrateOpen: boolean = false;

  public windowTop = 100;
  public windowLeft = 50;

  public canCreate: boolean = true;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _subs: Subscription[] = [];
  private scrollPosition = { top: 0, left: 0 };

  constructor(
    private logger: LoggerService,
    public dataService: OpportunityReviewsService,
    private notificationService: NotificationService,
    public faIcons: FaIconLibrary,
    private me: MeService,
    private renderer: Renderer2
  ) {
    faIcons.addIcons(faTrash, faArrowUp);
  }

  public sort: SortDescriptor[] = [
    {
      field: "id",
      dir: "desc",
    },
  ];

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...',);

    const { can_create } = this.me.allICanDo(REVIEW);
    this.logger.debug('ngOnInit:permissions: ', can_create);
    this.canCreate = can_create;

    this.dataService.query(this.opportunity.id);
    this._subs.push(this.dataService.subscribe(e => {
      this.logger.debug('ngOnInit:v: ', e);
      this.rawData = e;
      this.loadData();
    }))
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
    unlockBodyScroll(this.renderer, this.scrollPosition);
  }

  //#endregion


  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): string[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => `${item[fieldName]}`);
    // return distinct(this.gridData, fieldName).map((item: any) => `${item[fieldName]}\u200B`);
  }

  //#endregion

  //#region Window handlers

  public onNew(): void {
    this.logger.debug("onNew...");
    this.addItemOpen = true;
  }

  public onItemSave(args: any): void {
    this.logger.debug('onItemSave...', args);
    this.handleSaveAndClose(args);
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onClose.emit();
  }

  public closeItem(): void {
    this.logger.debug('closeItem...');
    this.addItemOpen = false;
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentItem = dataItem;
    this.confirmDeleteItem = true;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentItem);
    this.confirmDeleteItem = false;
    if (status === 'delete') {
      this.handleRemove(this.currentItem);
    }
  }

  integrate(dataItem: any): void {
    this.logger.debug("integrate: ", dataItem);
    this.currentItem = dataItem;
    this.integrateOpen = true;
    lockBodyScroll(this.renderer, this.scrollPosition);
  }

  closeIntegrate(): void {
    this.logger.debug('closeIntegrate...');
    this.integrateOpen = false;
    unlockBodyScroll(this.renderer, this.scrollPosition);
  }

  onIntegrate(args: any): void {
    this.logger.debug('onIntegrate...', args);
    this.integrateOpen = false;
    this.handleIntegrateAndClose(args)
  }
  //#endregion

  //#region Private functions

  private loadData(): void {
    this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  private handleSaveAndClose(args: any): void {
    this.logger.debug('handleSaveAndClose...', args);
    const { action, data } = args;
    this.dataService.add(data)
      .then(resp => {
        this.notify('Review Sent')
        this.dataService.query(this.opportunity.id);
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
          this.notify("Error Sending Review", true);
      })
      .finally(() => this.addItemOpen = false);
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.destroy(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.notify("Review Deleted");
        this.dataService.query(this.opportunity.id);
      })
      .catch(err => {
        this.logger.error('handleRemove:err: ', err);
        this.notify("Error Deleting Review", true);
      });
  }

  private handleIntegrateAndClose(args: any): void {
    this.logger.debug('handleIntegrateAndClose...', args);
    const { action, data } = args;
    this.dataService.integrate(data)
      .then(resp => {
        this.notify('Review Integrated')
        this.dataService.query(this.opportunity.id);
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
        this.notify("Error Integrating Review", true);
      })
      .finally(() => this.addItemOpen = false);
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: !error ? "success" : "error", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }

  //#endregion
}
